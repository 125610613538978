<template>
  <div>
    <img src="../../assets/images/no-results.svg" :style="'max-width:' + imageWidth" alt="Not found image" >
    <div class="text-neutral-07" :class="textSize">
        {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'not-found-img',
  props: {
    text: String,
    imageWidth: {
      type: String,
      default: '',
    },
    textSize: {
      type: String,
      default: 'heading-06',
    },
  },
};
</script>
<style scoped>
img {
  max-width: 300px;
}
div {
  display: block;
  text-align: center;
  margin-top: 25px;
}
</style>
