<template>
  <div class="col-auto p-0" v-for="item in 5" :key="item" :class="{ 'pe-2px': item < 5 }">
    <div v-if="item <= stars">
      <img src="../../assets/images/icons/filled-star-20x20.svg" alt="..." width="20" height="20"/>
    </div>
    <div v-else>
      <img src="../../assets/images/icons/empty-star-20x20.svg" alt="..." width="20" height="20"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Rating',
  props: {
    stars: {},
  },
};
</script>
<style scoped>
.pe-2px {
  padding-right: 2px !important;
}
</style>
